import type { BusinessType } from '@b2x/profile-library';

export interface ToggleOption {
  contextId: BusinessType;
  label: string;
}

export interface ToggleConfig {
  visibility: boolean;
}

export interface AppProps {
  contextConfig: ToggleOption[] | null;
  integrationType: string | null;
}

interface AEMConfig {
  routingEnabled: boolean;
  available: boolean;
  userSessionRequired: boolean;
}

export interface AEMComponentConfig extends Record<string, unknown> {
  payload: ToggleConfig;
  config: AEMConfig;
}

type CustomerType = '1A' | '2B' | '2C';

export enum ProfileSource {
  URL = 'url',
  PAGE = 'page',
  PERSISTED = 'persisted',
  USER = 'user',
}

interface Profile {
  id: string;
  displayName?: string;
  customerGroupId: string;
  market: string;
  customerType: CustomerType;
  businessType: BusinessType;
  meId?: string | null;
}

export interface ProfileState {
  activeProfile: Profile;
  availableProfiles: Profile[];
  source: ProfileSource;
}

export interface BusinessContextParams {
  businessType?: BusinessType;
  source?: ProfileSource;
}

export interface PlDispatchers {
  setBusinessType: (options: BusinessContextParams) => void;
}

export enum TRACKING_CLICK_LABEL {
  B2B = 'toggle.business',
  B2C = 'toggle.private',
}
