import { defineCustomElements } from 'https://assets.oneweb.mercedes-benz.com/plugin/workbench/core/7.21.0/loader/index.js';

import { createApp, h, defineCustomElement, getCurrentInstance } from 'vue';

import UCMC from './app.ce.vue';
import { buildTime, version } from './version';
import { logger } from './logger';

const componentName = 'cscs-user-context-manager';

const nearestElement = (el: any) => {
  while (el?.nodeType !== 1 && el?.parentElement) el = el.parentElement;
  return el;
};

export const defineElement = (component: any, { plugins = [] } = {} as any) =>
  defineCustomElement({
    props: component.props,
    setup(props) {
      // @ts-ignore-next-line
      const app = createApp();

      plugins.forEach(app.use);

      app.mixin({
        mounted() {
          const insertStyles = (styles: string[]) => {
            if (styles?.length && this.$el) {
              this.__style = document.createElement('style');
              this.__style.innerText = styles.join().replace(/\n/g, '');
              const parent = nearestElement(this.$el);
              if (parent?.nodeType === 1) {
                parent.prepend(this.__style);
              }
            }
          };

          insertStyles(this.$?.type.styles);

          if (this.$options.components) {
            for (const comp of Object.values<any>(this.$options.components)) {
              insertStyles(comp.styles);
            }
          }
        },
        unmounted() {
          this.__style?.remove();
        },
      });

      const instance = getCurrentInstance();

      if (instance) {
        Object.assign(instance.appContext, app._context);
        Object.assign((instance as any).provides, app._context.provides);
      }

      return () => h(component, props);
    },
  });

const bootstrap = async () => {
  logger.log(`User Context Manager v${version} (${buildTime})`);

  await defineCustomElements(window, {
    transformTagName: (tagName: string) => `ui-${tagName}`,
  });

  if (!window.customElements.get(componentName)) {
    window.customElements.define(componentName, defineElement(UCMC, { plugins: [] }));
  }
};

bootstrap();
