<template>
  <div v-if="visibility" class="ucmc-toggle" :dir="orientation">
    <label
      data-test-id="ucmc-toggle-label-1"
      class="ucmc-toggle__label wb-text-l-strong-static"
      :class="{ 'ucmc-toggle__active': !checked }"
    >
      <wb-text></wb-text>{{ contextConfigParsed[0].label }}
    </label>

    <ui-wb-toggle ref="toggleEl" data-test-id="ucmc-toggle-wb" :checked="checked" @wbchange="onToggle"></ui-wb-toggle>

    <label
      data-test-id="ucmc-toggle-label-2"
      class="ucmc-toggle__label wb-text-l-strong-static"
      :class="{ 'ucmc-toggle__active': checked }"
    >
      {{ contextConfigParsed[1].label }}
    </label>
  </div>
</template>

<script setup lang="ts">
import { CONNECTIONS, getConnectionDispatchers } from '@b2x/core/bundle';
import { BusinessType } from '@b2x/profile-library';
import type { ConnectionAemEnvironmentState } from '@owc/connection-aem-environment';
import { type Subscription, initializeStore } from '@seamless/store';
import { computed, onMounted, onUnmounted, ref, withDefaults } from 'vue';
import {
  type AppProps,
  type BusinessContextParams,
  type PlDispatchers,
  ProfileSource,
  type ProfileState,
  type ToggleOption,
} from './common';
import { logger } from './logger';
import { version } from './version';
import { TrackingService } from './trackingService';

const props = withDefaults(defineProps<AppProps>(), {
  contextConfig: null,
  // checkout does not have attribute (prop) called integrationType, so it will be null, so we hide it
  integrationType: null,
});

logger.log(`Toggle initialize: ${version}`);

const store = initializeStore();
let profileLibrarySubscription: Subscription | null = null;

const toggleEl = ref<HTMLElement>();
const orientation = ref('ltr');
const country = ref('DE');
const visibility = ref(false);
const checked = ref(false); // false = B2C, true = B2B

const contextConfigParsed = computed((): ToggleOption[] => {
  const defaultOptions: ToggleOption[] = [
    {
      label: 'Private',
      contextId: BusinessType.B2C,
    },
    {
      label: 'Business',
      contextId: BusinessType.B2B,
    },
  ];

  return typeof props.contextConfig === 'string' ? JSON.parse(props.contextConfig) : defaultOptions;
});

const trackingService = new TrackingService();

onMounted(() => {
  // in WB7 we must use JS to change the color of the toggle as per our design
  var style = document.createElement('style');
  style.innerHTML = 'label:before { background-color: var(--wb-blue-45) !important; }';
  toggleEl.value!.shadowRoot!.appendChild(style);
});

store.once(CONNECTIONS.AEM_CONNECTION_NAME, (state: ConnectionAemEnvironmentState) => {
  country.value = state.country;
  orientation.value = state.languageDirection;
});

visibility.value = Boolean(props.integrationType);

profileLibrarySubscription = store.subscribe(CONNECTIONS.PROFILE_CONNECTION_NAME, (state: ProfileState) => {
  checked.value = state.activeProfile.businessType === BusinessType.B2B;
});

const onToggle = (event: Event): void => {
  const isChecked = (event.target as HTMLInputElement).checked;

  const params: BusinessContextParams = {
    businessType: isChecked ? BusinessType.B2B : BusinessType.B2C,
    source: ProfileSource.USER,
  };

  getConnectionDispatchers<PlDispatchers>(CONNECTIONS.PROFILE_CONNECTION_NAME).then((dispatchers) => {
    dispatchers.setBusinessType(params);
  });

  trackingService.pushEvent(params.businessType as BusinessType);
};

onUnmounted(() => {
  if (profileLibrarySubscription) {
    profileLibrarySubscription.unsubscribe();
  }
});
</script>

<style lang="scss">
@import url('https://assets.oneweb.mercedes-benz.com/plugin/workbench/core/7.42.0/css/globals.css');

.ucmc-toggle {
  color: var(--wb-grey-20);
  display: flex;
  align-items: center;

  &__label:first-of-type {
    margin-right: var(--wb-spacing-xxs);
  }

  &__active {
    color: var(--wb-blue-45);
  }
}
</style>
