import { initializeLogger } from '@seamless/logger';
export const seamlessLogger = initializeLogger('CSCS-USER-CONTEXT-MANAGER');

const loggerDebugNamespaces = 'loggerDebugNamespaces';
const cscsNamespace = 'CSCS-USER-CONTEXT-MANAGER';

enum LOG_TYPE {
  LOG = 'log',
  ERROR = 'error',
  DEBUG = 'debug',
  WARN = 'warn',
}

class Logger {
  private loggingEnabled = false;
  constructor(private _logger: typeof seamlessLogger) {
    const item = localStorage.getItem(loggerDebugNamespaces);
    if (item) {
      this.loggingEnabled = item.includes(cscsNamespace);
    }
  }

  _log(type: string, args: unknown[]) {
    if (this.loggingEnabled) {
      (this._logger as unknown as Record<string, { (...a: unknown[]): void }>)[type](...args);
    }
  }

  log(...args: unknown[]) {
    this._log(LOG_TYPE.LOG, args);
  }

  error(...args: unknown[]) {
    this._log(LOG_TYPE.ERROR, args);
  }

  debug(...args: unknown[]) {
    this._log(LOG_TYPE.DEBUG, args);
  }

  warn(...args: unknown[]) {
    this._log(LOG_TYPE.WARN, args);
  }
}

export const logger = new Logger(seamlessLogger);
