import { TrackingEvent, getTrackingDispatchers } from '@owc/connection-aem-tracking';

import { logger } from './logger';
import type { BusinessType } from '@b2x/profile-library';
import { TRACKING_CLICK_LABEL } from './common';

import { version } from './version';

export class TrackingService {
  private eventAction: string;

  constructor() {
    this.eventAction = 'customer_type_context.switch';
  }

  pushEvent(businessType: BusinessType): void {
    const eventToBePushed: TrackingEvent = {
      event: 'click',
      eventDetail: {
        category: 'feature',
        action: this.eventAction,
        label: TRACKING_CLICK_LABEL[businessType],
      },
      application: {
        id: 'cscs',
        version,
        parentApplicationId: 'aem',
      },
    };

    logger.log(`Send a click event to the tracker: ${JSON.stringify(eventToBePushed)}`);

    getTrackingDispatchers()
      .then((dispatchers) => {
        logger.log(`AEM Event pushed ${JSON.stringify(eventToBePushed)}`);
        dispatchers.pushTrackingEvent(eventToBePushed);
      })
      .catch(() => {
        window.dataLayer_ow.push(eventToBePushed);
      });
  }
}
